$(document).ready(function () {
  $('.menuButton').click(function () {
    var header = $('header');
    if (header.hasClass('open')) {
      header.removeClass('open');
    } else {
      header.addClass('open');
    }
  });
});
